<script>
    import { Input } from "@sveltestrap/sveltestrap";
    import { onMount } from "svelte";

    let selectedLang = "en";

    // get language from cookie
    export let language;

    // when language changes
    const onChange = () => {
        window.location = "/switch?lang=" + selectedLang;
    }

    // change language if needed
    onMount(() => {
        if(language) selectedLang = language;
    });

</script>

<language-picker>
    <Input type="select" bind:value={selectedLang} on:change={onChange}>
        <option value="en">English</option>
        <option value="my">Malay</option>
        <option value="lo">Lao</option>
    </Input>
</language-picker>

<style></style>
<script>
    import Layout from "./Layout.svelte";
    import { Container, Row, Col, Button, Image } from "@sveltestrap/sveltestrap";
    import Fa from "svelte-fa";
    import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
    import { getContext } from "svelte";
    import { init, __ } from "../i18n";
    import LanguagePicker from "./LanguagePicker.svelte";
    
    export let title;
    export let errMsg;
    export let language;
    
    const { catalog } = getContext("global.stores");
    init($catalog);
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<Layout>
    <local>
        <Container>
            <Row class="offset-xl-9 offset-lg-8 col-xl-3 col-lg-4 col-md-5 col-sm-11 text-end">
                <Col class="mt-3 px-0">
                    <Row>
                        <Col class="col-xl-12">
                            <!--<span class="">{__("CHOOSE_LANG_TXT")}</span>-->
                            <div class="ms-2 d-inline-block">
                                <LanguagePicker {language} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row class="box col-xl-3 col-lg-4 col-md-5 col-sm-11 mx-auto text-center">
                <Col>
                    <Row class="header">
                        <Image src="images/nec-logo.svg" alt="Logo Image" class="mx-auto" />
                        <h4 class="mb-0 mt-2">{__("APP_NAME")}</h4>
                    </Row>
                    <Row class="mt-3">
                        <Col class="col-12 mt-4">
                            <Fa icon={faUserCircle} size="4x" class="fa-user-circle mb-4" />
                        </Col>
                        <Col class="col-12">
                            <h5>{__("SIGNIN_TXT")}</h5>
                        </Col>
                    </Row>
                    {#if errMsg}
                    <Row>
                        <Col><span class="btn-danger err-msg">{errMsg}</span></Col>
                    </Row>
                    {/if}
                    <Row>
                        <Col>
                            <a href="/login"><Button color="custom" class="mt-5 mb-4">{__("SIGNIN_BTN")}</Button></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </local>
</Layout>

<style>
    local :global(.err-msg) {
        color: var(--bs-btn-bg);
        font-weight: 500;
    }
    local :global(.box) {
        margin-top: 15vh !important;
    }
    local :global(img) {
        width: 150px;
    }
    local :global(.box) {
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    local :global(.header) {
        background-color: var(--login-bg-color);
        padding: 30px 30px 20px;
        color: #fff;
    }
    local :global(h5) {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.1em;
    }
    local :global(button) {
        background-color: var(--btn-bg-color);
        border-color: var(--btn-bg-color);
        color: var(--btn-text-color);
        border-width: 2px;
    }
    local :global(button:hover),
    local :global(button:active) {
        background-color: var(--btn-hover-bg-color) !important;
        border-color: var(--btn-hover-bg-color) !important;
        color: var(--btn-text-color) !important;
    }
    local :global(button:active) {
        border-style: inset;
    }
</style>